import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"

import TabBar from "../components/policyTabs"
import Container from "../components/container"

export default function PrivacyPolicy({ location }) {
  return (
    <Page>
      <SEO title="Privacy Policy" />
      <Container>
        <h1 className="tandc">Terms and Conditions</h1>
      </Container>
      <TabBar location={location} />
      <Container>
        <div className="text-container">
          <h1>Privacy Policy</h1>

          <p>
            We are committed to respecting your privacy. Our Privacy Policy sets
            out how we collect, use, store and disclose your personal
            information. We are bound by the Australian Privacy Principles
            contained in the Privacy Act.
          </p>
          <p>
            By providing personal information to us, you consent to our
            collection, use and disclosure of your personal information in
            accordance with this Privacy Policy and any other arrangements that
            apply between us. We may change our Privacy Policy from time to time
            by publishing changes to it on our website. We encourage you to
            check our website periodically to ensure that you are aware of our
            current Privacy Policy.
          </p>
          <p>
            In this Privacy Policy, ‘us’ ‘we’ or ‘our’ means TravelbyBit Pty Ltd
            (ABN 88 621 527 357), its associated entities and any related bodies
            corporate.
          </p>
          <h3>What Personal Information Do We Collect?</h3>

          <p>
            Personal information has the meaning given under your local data
            protection law. We collect personal information about you in order
            to facilitate your travel bookings and arrangements, and to provide
            you with our goods and services.
          </p>
          <p>We may collect the following types of personal information:</p>
          <ul>
            <li>name;</li>
            <li>mailing or residential address;</li>
            <li>email address;</li>
            <li>telephone number and other contact details;</li>
            <li>passport information;</li>
            <li>credit card information;</li>
            <li>loyalty program details (such as Frequent Flyer details);</li>
            <li>information about your health (if disclosed);</li>
            <li>
              your device ID, device type, geo-location information, computer
              and connection information, statistics on page views, traffic to
              and from the sites, ad data, IP address and standard web log
              information;
            </li>
            <li>
              details of the products and services we have provided to you or
              that you have enquired about, including any additional information
              necessary to deliver those products and services and respond to
              your enquiries;
            </li>
            <li>
              any additional information relating to you that you provide to us
              through your use of our website, in person or through other
              websites or accounts from which you permit us to collect
              information;
            </li>
            <li>information you provide to us through customer surveys; or</li>
            <li>
              any other personal information that may be required in order to
              facilitate your dealings with us.
            </li>
          </ul>
          <p>
            We may collect these types of personal information either directly
            from you, or from third parties, such as when another person makes a
            travel booking on your behalf (e.g. a family member or friend). We
            may collect this information when you:
          </p>
          <ul>
            <li>register on our website;</li>
            <li>
              communicate with us through correspondence, chats, email, or when
              you share information with us from other social applications,
              services or websites; or
            </li>
            <li>interact with our sites, services, content and advertising.</li>
          </ul>
          <p>
            In addition, when you apply for a job or position with us, we may
            collect certain information from you (including your name, contact
            details, working history and relevant records checks) from any
            recruitment consultant, your previous employers and others who may
            be able to provide information to us to assist in our decision on
            whether or not to make you an offer of employment or engage you
            under a contract. This Privacy Policy does not apply to acts and
            practices in relation to employee records of our current and former
            employees, which are exempt from the Privacy Act.
          </p>
          <h3>What Sensitive Information Do We Collect?</h3>

          <p>
            Sensitive information will only be collected by us with your
            consent, and in compliance with your local data protection laws, to
            facilitate your travel booking(s). Your sensitive information will
            only be used for the purpose for which it was collected, unless you
            subsequently consent to us using it for another purpose.
          </p>
          <p>
            Sensitive information, as defined by law, is any information about a
            person’s racial or ethnic origin, sexual orientation or practices,
            criminal record or the alleged commission of an offence, political
            opinion, membership of a political association, religious beliefs or
            affiliations, philosophical beliefs, membership of a political,
            professional or trade association, biometric and genetic
            information, and health information.
          </p>
          <h3>How Do We Use Your Personal Information?</h3>

          <p>
            We may collect and use your personal information for the following
            purposes:
          </p>
          <ul>
            <li>to enable you to access and use our website and services;</li>
            <li>
              to make arrangements on your behalf with travel service providers;
            </li>
            <li>to collect and process payments;</li>
            <li>
              to send you service, support and administrative messages,
              reminders, technical notices, updates, security alerts, and
              information requested by you;
            </li>
            <li>
              to send you marketing and promotional messages and other
              information that may be of interest to you, including information
              sent by, or on behalf of, our business partners that we think you
              may find interesting;
            </li>
            <li>
              to administer rewards, surveys, contests, or other promotional
              activities or events sponsored or managed by us or our business
              partners;
            </li>
            <li>
              to operate, protect, improve and optimise our website, services,
              business and our users’ experience, such as to perform analytics,
              conduct research and for advertising and marketing;
            </li>
            <li>
              to comply with our legal obligations, resolve any disputes that we
              may have with any of our users, and enforce our agreements with
              third parties; and
            </li>
            <li>to consider your employment application.</li>
          </ul>
          <h3>To Whom Do We Disclose Your Personal Information?</h3>

          <p>
            We may disclose personal information for the purposes described in
            this privacy policy to:
          </p>
          <ul>
            <li>
              our related bodies corporate and travel service providers that you
              have booked with (such as airlines, accommodation providers, tour
              operators and other travel service providers).
            </li>
            <li>our employees and agents;</li>
            <li>
              third party suppliers and service providers (including providers
              for the operation of our websites and/or our business or in
              connection with providing our products and services to you);
            </li>
            <li>
              payment systems operators (eg merchants receiving card payments);
            </li>
            <li>
              our existing or potential agents, business partners or partners;
            </li>
            <li>
              our sponsors or promoters of any competition that we conduct via
              our services;
            </li>
            <li>
              anyone to whom our assets or businesses (or any part of them) are
              transferred, or will potentially be transferred to;
            </li>
            <li>
              other entities, including government agencies, regulatory bodies
              and law enforcement agencies, or as required, authorised or
              permitted by law.
            </li>
            <li>Cross-border Disclosure of Personal Information</li>
          </ul>
          <p>
            We may disclose your personal information to overseas third parties,
            generally when making a booking with an international travel service
            provider. However, we cannot ensure that third parties, including
            overseas third parties, will handle your information in accordance
            with privacy laws in your country.
          </p>
          <h3>Using Our Website and Cookies</h3>

          <p>
            We may collect personal information about you when you use and
            access our website.
          </p>

          <p>
            While we do not use browsing information to identify you personally,
            we may record certain information about your use of our website,
            such as which pages you visit, the time and date of your visit and
            the internet protocol address assigned to your computer.
          </p>
          <p>
            We may also use ‘cookies’ or other similar tracking technologies on
            our website that help us track your website usage and remember your
            preferences. Cookies are small files that store information on your
            computer, TV, mobile phone or other device. They enable the entity
            that put the cookie on your device to recognise you across different
            websites, services, devices and/or browsing sessions. You can
            disable cookies through your internet browser, but our website may
            not work as intended for you if you do so.
          </p>

          <p>
            We may also use cookies to enable us to collect data that may
            include personal information. For example, where a cookie is linked
            to your account, it will be considered personal information under
            the Privacy Act. We will handle any personal information collected
            by cookies in the same way that we handle all other personal
            information as described in this Privacy Policy.
          </p>
          <h3>Google Analytics</h3>

          <p>
            We use Google Analytics, a website analysis service provided by
            Google, Inc (“Google”), to analyse website statistics. Google
            Analytics places cookies on your computer’s browser, those cookies
            generate information about your use of our website and that
            information is then transmitted to Google. The information is used
            to analyse the number of visits to our website and its pages, the
            average time spent on the site and the pages viewed.
          </p>
          <p>
            We will not (and will not allow any third party to) use Google
            Analytics to track or to collect any personal data of visitors to
            our website. We will not associate any data gathered via use of
            cookies with any personal data from any source as part of our use of
            Google Analytics.
          </p>
          <p>
            For further information about Google Analytics, please click here.
            For details of Google Analytics’ Privacy Policy, please click here.
          </p>
          <p>
            You may choose to opt-out of Google Analytics using the Google
            Analytics Opt-out Browser Add-on, which you may access here.
          </p>
          <h3>Storage and Security</h3>

          <p>
            We may hold your personal information in either electronic or hard
            copy form. We take reasonable steps to protect your personal
            information from misuse, interference and loss, as well as
            unauthorised access, modification or disclosure and we use a number
            of physical, administrative, personnel and technical measures to
            protect your personal information. However, we cannot guarantee the
            security of your personal information due to inherent risks
            associated with the transmission of personal information via the
            internet.
          </p>

          <h3>Links</h3>

          <p>
            Our website may contain links to websites operated by third parties.
            Those links are provided for convenience and may not remain current
            or be maintained. Unless expressly stated otherwise, we are not
            responsible for the privacy practices of, or any content on, those
            linked websites, and have no control over or rights in those linked
            websites. The privacy policies that apply to those other websites
            may differ substantially from our Privacy Policy, so we encourage
            individuals to read them before using those websites.
          </p>

          <h3>Accessing or Amending Your Personal Information</h3>

          <p>
            We respect your privacy and will provide you with reasonable access
            to the personal information you have provided to us through your use
            of our services. If we hold personal information about you that is
            not accurate, complete or up-to-date, you may request us to change
            that information. Personal information provided by you must not be
            false or misleading. We assume that the information you provide us
            is accurate and will be updated when the relevant personal
            information changes. We will respond to your request within a
            reasonable time. Please note we may need to verify your identity
            when you request your personal information.
          </p>
          <p>
            We reserve the right to deny you access for any reason permitted
            under applicable law. If we deny access or amendment, we will
            provide you with written reasons for such denial unless it is
            unreasonable to do so and, where required by local data protection
            laws, will note your request and the denial of same in our records.
          </p>
          <p>
            If you would like to access or amend any information we hold about
            you, please contact us on the details provided at the end of this
            Privacy Policy.
          </p>
          <h3>Data Retention</h3>

          <p>
            If the user is subscribed to the mailing list, the data we collect
            is retained until the user opts out of the mailing list. If a user
            is not subscribed to the mailing list but has provided personal
            information, data is stored for a limited period of time to fulfil
            the purposes for which the data was originally collected. The data
            will be used and retained as necessary to meet our legal
            obligations.
          </p>
          <p>
            If you would like to opt-out of receiving marketing communications
            from us, please contact us on the details provided at the end of
            this Privacy Policy.
          </p>
          <h3>Privacy Complaints</h3>

          <p>
            If you have any questions about our Privacy Policy, or if you have a
            complaint about our handling of your personal information, please
            contact us using the contact information below. We aim to resolve
            your query as soon as practicable.
          </p>
          <h3>Contact Us</h3>

          <p>
            For further information about our Privacy Policy or practices, to
            make a complaint, or to access or correct your personal information,
            please contact us using the details set out below:
          </p>

          <p>Contact Number (Australia): 1300 445 564</p>

          <p>Contact Number (International): +61 7 3151 3780</p>

          <p>Effective: 19 December 2017</p>

          <p>Updated: 4 October 2018</p>
        </div>
      </Container>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }

  p {
    margin: 1rem 0;
  }

  li {
    margin: 0.5rem 0;
  }
`
